import useFetchTableRoom, {
  UseFetchTableRoomProps,
} from "./useFetchTableRoom";

export default function useTableRooms(props: UseFetchTableRoomProps) {
  const {
    data,
    loading,
    loadError,
  } = useFetchTableRoom(props);

  return {
    loading,
    loadError,
    data,
  };
}

export type UseTableRooms = ReturnType<typeof useTableRooms>;
