import { useQuery, gql } from "@apollo/client";
import { CircularProgress, Stack } from "@mui/material";
import Select from "../../../components/Select";
import styles from "./styles";

export default function RoomSelector(props: {
  room: string | null;
  setRoom: (v: string | null) => void;
}) {
  const { loading, error, data } = useQuery<queryResponse>(GET_TABLE_ROOMS);

  if (loading) return <CircularProgress />;
  if (error) return <>Error</>;

  const rooms = data ? data.tableRooms : [];

  return (
    <Stack
      sx={styles.venueSelector}
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <Select
        sx={{ minWidth: "170px", backgroundColor: "#fff" }}
        size="small"
        label="フィルター条件"
        options={[{ value: "all", name: "すべての席" }].concat(
          rooms.map((r) => ({ value: r.id, name: r.name }))
        )}
        value={props.room ? props.room : "all"}
        onChange={props.setRoom}
      />
    </Stack>
  );
}

const GET_TABLE_ROOMS = gql`
  query GetTableRooms {
    tableRooms {
      id
      name
    }
  }
`;

interface queryResponse {
  tableRooms: { id: string; name: string }[];
}
