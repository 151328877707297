import { gql, useQuery } from "@apollo/client";
import { startOfMonth, endOfMonth } from "date-fns";
import { formatDate } from "../helpers";

export type UseFetchTableRoomProps = {
  month: Date;
  roomId?: string | null;
};

export default function useFetchTableRoom({ month, roomId }: UseFetchTableRoomProps) {

  const { data, loading, error: loadError } = useQuery<GetScheduleResponse>(
    GET_TABLE_ROOM_AVAILABILITY,
    {
      variables: {
        from: formatDate(startOfMonth(month)),
        until: formatDate(endOfMonth(month)),
        roomId,
      },
      fetchPolicy: "no-cache",
    }
  );

  return {
    data,
    loading,
    loadError,
  }
}

type GetScheduleResponse = any;

const GET_TABLE_ROOM_AVAILABILITY = gql`
  query GetTableRoomAvailability($from: Date!, $until: Date!, $roomId: String) {
    tableRoomsAvailability(from: $from, until: $until, roomId: $roomId) {
      date
      times {
        time
        numBookings
        numApprovals
      }
    }
  }
`;
