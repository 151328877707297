import { Chip } from "@mui/material";
import { Plan } from "../../List";
import FaqList from "./components/FaqList";
import { FaqsField } from "./types";
import { useFieldArray } from "react-hook-form";
import { v4 as uuidV4 } from "uuid";
import { FormContext } from "../components/FormContext";
import { useContext } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { setArrayItemValue } from "../formValues";

function FaqsEditorForm() {
  const { form } = useContext(FormContext);
  const fieldArray = useFieldArray({
    name: FaqsField.faqs,
    control: form.control,
  });
  const { append, fields } = fieldArray;

  return (
    <>
      <FaqList fieldArray={fieldArray} planId={form.planId} />

      <Chip
        style={{ marginTop: "1rem" }}
        label={"FAQを追加"}
        icon={<AddCircleIcon />}
        clickable
        onClick={() => {
          append({});
          setArrayItemValue({
            form,
            arrayName: FaqsField.faqs,
            itemIndex: fields.length,
            value: {
              [FaqsField.question]: "",
              [FaqsField.answer]: "",
              [FaqsField.imageUrl]: null,
              [FaqsField.priority]: fieldArray.fields.length,
              [FaqsField.id]: uuidV4(),
              [FaqsField.isDeleted]: false,
            },
          });
        }}
      />
    </>
  );
}

function getDefaultValuesFaqs(plan: Plan) {
  if (!plan.publishedPlan.json.faqs) {
    const sortedFaqs = [...plan.faqs]
      .sort((a, b) => a.priority - b.priority)
      .map((faq) => ({
        ...faq,
        [FaqsField.isDeleted]: false,
        [FaqsField.id]: faq.id,
      }));
    return {
      [FaqsField.faqs]: sortedFaqs,
    };
  } 
  const sortedFaqs = [...plan.publishedPlan.json.faqs]
    .sort((a, b) => a.priority - b.priority)
    .map((faq) => ({
      ...faq,
      [FaqsField.isDeleted]: false,
      [FaqsField.id]: faq.id,
    }));
  return {
    [FaqsField.faqs]: sortedFaqs,
  };
}

const faqExport = {
  form: FaqsEditorForm,
  getDefaultValues: getDefaultValuesFaqs,
};
export default faqExport;
