import { default as MuiModal } from "@mui/material/Modal";
import { add, format, isBefore, set, sub } from "date-fns";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "./styles";
import RoomSelector from "../../RoomSelector";
import ja from "date-fns/locale/ja";
import {
  TableContainer,
  Table as MuiTable,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

interface GetTableRoomDateResponse {
  tableRoomsDate: {
    id: string;
    name: string;
    totalApprovals: number;
    times: {
      time: string;
      numBookings: number;
      numApprovals: number;
    }[];
  }[];
}

export default function Modal(props: ModalProps) {
  const [room, setRoom] = useState<string | null>(props.roomSelected ?? null);

  const {
    data,
    loading,
    error: loadError,
  } = useQuery<GetTableRoomDateResponse>(GET_TABLE_ROOM_DATE, {
    variables: {
      venueIds: [],
      date: props.dateSelected,
      roomId: room == "all" ? null : room,
    },
    fetchPolicy: "no-cache",
  });

  if (loadError) return <>Error</>;

  const totalNumBookings = data?.tableRoomsDate.reduce(
    (sum, room) =>
      sum + room.times.reduce((t, time) => t + time.numBookings, 0),
    0
  );

  const totalNumApprovals = data?.tableRoomsDate.reduce(
    (sum, room) =>
      sum + room.times.reduce((t, time) => t + time.numApprovals, 0),
    0
  );
  const numAvailable = (totalNumBookings ?? 0) - (totalNumApprovals ?? 0);

  return (
    <MuiModal
      open={props.value}
      onClose={() => props.setValue(false)}
      disableAutoFocus={true}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 900,
          // maxHeight: 555,
          minHeight: 300,
          overflowY: "auto",
          backgroundColor: "rgb(255, 255, 255, 1)",
          borderRadius: "5px",
          p: 4,
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box
              sx={{
                paddingBottom: 2,
                borderBottom: "1px solid #000",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DaySelectorControls
                date={
                  props.dateSelected ? new Date(props.dateSelected) : new Date()
                }
                setDate={props.setDateSelected}
              />
              <Box>
                最大席数{totalNumBookings}/予約数{numAvailable}
              </Box>
              <RoomSelector room={room} setRoom={setRoom} />
            </Box>
            <Box
              component="section"
              sx={{ textAlign: "center", paddingBottom: 2 }}
            >
              <TableContainer sx={{ maxHeight: "555px" }}>
                <MuiTable stickyHeader>
                  <TableHead>
                    <TableRow
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 15,
                        background: "white",
                      }}
                    >
                      <TableCell sx={{ width: "65%" }} align="center">
                        席名
                      </TableCell>
                      <TableCell sx={{ width: "35%" }} align="center">
                        予約
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.tableRoomsDate.map((tableRoom, index) => {
                      return (
                        tableRoom.times &&
                        tableRoom.times.length > 0 && (
                          <TableRow key={index}>
                            <TableCell align="center">
                              <Box
                                sx={{
                                  border: "1px solid #d7d7d7",
                                  padding: "20px 50px",
                                }}
                              >
                                <p>{tableRoom.name}</p>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: 2,
                                    alignItems: "center",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box sx={{ minWidth: "max-content" }}>
                                    <a href="/plans">Plan list</a>
                                  </Box>
                                  <Grid
                                    container
                                    spacing={3}
                                    sx={{
                                      width: "100%",
                                      marginLeft: "0px",
                                      marginTop: "0px",
                                      marginRight: "50px",
                                      border: "1px solid #d7d7d7",
                                      maxHeight: "218px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {tableRoom.times.map((item, index) => (
                                      <Grid
                                        key={index}
                                        sx={{
                                          border: "solid 1px #d7d7d7",
                                          padding: "5px !important",
                                          backgroundColor:
                                            item.numApprovals >=
                                            item.numBookings
                                              ? "#D9D9D9"
                                              : "",
                                        }}
                                        item
                                        xs={4}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          sx={{ fontSize: "13px" }}
                                        >
                                          {item.time}
                                        </Typography>
                                        {item.numBookings - item.numApprovals <=
                                        0 ? (
                                          <Typography
                                            variant="h6"
                                            sx={{ fontSize: "14px" }}
                                          >
                                            X
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="h6"
                                            sx={{ fontSize: "14px" }}
                                          >
                                            残
                                            {item.numBookings -
                                              item.numApprovals}
                                          </Typography>
                                        )}
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {tableRoom.totalApprovals}
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Box>
          </>
        )}
      </Box>
    </MuiModal>
  );
}

interface ModalProps {
  value: boolean;
  setValue: (value: boolean) => void;
  dateSelected: Date | null;
  setDateSelected: (date: Date) => void;
  roomSelected: string | null;
}

function DaySelectorControls(props: { date: Date; setDate: (m: any) => void }) {
  const currentMonth = set(new Date(), { date: 1, hours: 0, minutes: 0 });
  const nextDay = add(props.date, { days: 1 });
  const previousDay = sub(props.date, { days: 1 });
  const previousMonthIsPast = isBefore(previousDay, currentMonth);

  return (
    <Stack direction="row" spacing={3} alignItems="center">
      <Box
        sx={{
          fontSize: "16px",
        }}
      >
        {format(props.date, "M")}月 {format(props.date, "d")}日(
        {format(props.date, "E", { locale: ja })})
      </Box>
      <ButtonGroup sx={styles.monthChangeButtons}>
        <Button
          sx={{ ...styles.monthChangeButton, borderRadius: "13px 0 0 13px" }}
          disabled={previousMonthIsPast}
          onClick={() => props.setDate(format(previousDay, "yyyy-MM-dd"))}
        >
          <ArrowBackIosNewIcon fontSize="small" />
        </Button>
        <Button
          sx={{ ...styles.monthChangeButton, borderRadius: "0 13px 13px 0" }}
          onClick={() => props.setDate(format(nextDay, "yyyy-MM-dd"))}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    </Stack>
  );
}

const GET_TABLE_ROOM_DATE = gql`
  query GetTableRoomDate($venueIds: [ID]!, $date: Date!, $roomId: String) {
    tableRoomsDate(venueIds: $venueIds, date: $date, roomId: $roomId) {
      id
      name
      totalApprovals
      times {
        time
        numBookings
        numApprovals
      }
    }
  }
`;
