import { SxProps } from "@mui/material";

const monthChangeButtons: SxProps = { backgroundColor: "#fff" };

const monthChangeButton: SxProps = {
  "&:hover": {
    border: "unset",
    backgroundColor: "#C8A063",
    opacity: 0.8,
  },
  "&:disabled": {
    border: "unset",
  },
  color: "#fff",
  border: "unset",
  backgroundColor: "#C8A063",
  padding: "5px 0",
  minWidth: "30px !important",
};

const exports = {
  monthChangeButtons,
  monthChangeButton,
};
export default exports;
