import { useDrag, useDrop } from "react-dnd";
import React, { Dispatch, SetStateAction } from "react";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { PriceType } from "./ChangePlanButton";
import TextField from "../TextField";
import { ReservationItem } from "../../pages/Bookings/View";

const ItemType = "PRICE_TYPE";

interface PriceTypeItemProps {
  priceTypes: PriceType[];
  type: ReservationItem;
  index: number;
  movePriceType: (fromIndex: number, toIndex: number) => void;
  setPriceTypesOld: Dispatch<SetStateAction<any>>;
}

const PriceTypeOldItem: React.FC<PriceTypeItemProps> = ({
  type,
  index,
  movePriceType,
  priceTypes,
  setPriceTypesOld,
}: PriceTypeItemProps) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: ItemType,
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        movePriceType(draggedItem.index, index);
        draggedItem.index = index; // Update the dragged item's index to avoid redundant hover calls.
      }
    },
  });

  return (
    <div
      ref={(node) => dragRef(dropRef(node))}
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "12px",
        cursor: "grab",
        opacity: isDragging ? 0.3 : 1,
      }}
    >
      {index <= priceTypes.length - 1 ? (
        <KeyboardBackspaceRoundedIcon color="error" />
      ) : (
        <div style={{ minWidth: "25px" }} />
      )}
      <TextField
        style={{
          marginTop: 0,
          marginRight: 8,
        }}
        readOnly={true}
        value={type?.priceType?.name ?? ""}
        label={"Price type"}
        sx={{
          "& .MuiInputBase-input": {
            cursor: "grab",
          },
        }}
      />
      <TextField
        style={{
          marginTop: 0,
          marginRight: 8,
        }}
        readOnly={true}
        value={type?.priceType?.unitType ?? ""}
        label={"Unit type"}
        sx={{
          "& .MuiInputBase-input": {
            cursor: "grab",
          },
        }}
      />
      <TextField
        type="number"
        style={{ marginTop: 0 }}
        value={type?.quantity ?? ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (Number(e.target.value) < 1) return;
          setPriceTypesOld((oldData: ReservationItem[]) => {
            return oldData?.map((item: ReservationItem) => {
              if (type.id === item.id) {
                return {
                  ...item,
                  quantity: Number(e.target.value),
                };
              }
              return item;
            });
          });
        }}
        label={"Quantity"}
        sx={{
          "& .MuiInputBase-input": {
            cursor: "grab",
          },
        }}
      />
    </div>
  );
};

export default PriceTypeOldItem;
