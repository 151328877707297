import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";

import Card from "../../../components/Card";
import TextField from "../../../components/TextField";
import { bookingStatusColors } from "../../../helpers/booking-status-colors";
import { bookingStatusTranslations } from "../../../helpers/booking-status-translations";
import paymentMethodTranslation from "../../../helpers/payment-method-translation";
import { useNotifications } from "../../../components/Notification";
import ChangeDateTimeButton from "../../../components/Buttons/ChangeDateTimeButton";
import itemOrderStatusTranslations from "../../../helpers/item-order-status-translations";
import { ActivityJson, Booking, PriceTypeJson } from "../../../types";
import ChangePlanButton from "../../../components/Buttons/ChangePlanButton";

const ITEM_BAG_FEE_JPY = 280;

export type DataEditItem = {
  fieldName: string;
  oldValue: string;
  newValue: string;
  editStatus: string;
};

export enum EditStatus {
  ADDED = "ADDED",
  REMOVED = "REMOVED",
  TOUCHED = "TOUCHED",
  UNTOUCHED = "UNTOUCHED",
}

export type ViewBookingContextValue = {
  newPlan: Plan | null;
  setNewPlan: Dispatch<SetStateAction<any>>;
  setActivity: Dispatch<SetStateAction<any>>;
  activity: Activity | null;
  setSeatOption: Dispatch<SetStateAction<any>>;
  seatOption: SeatOption | null;
  setPriceTypes: Dispatch<SetStateAction<any>>;
  priceTypes: PriceType[] | null;
  setBookingPrice: Dispatch<SetStateAction<any>>;
  bookingPrice: number | null;
  clearData: () => void;
  productPrice: number;
  newVenue: Venue | null;
  setNewVenue: Dispatch<SetStateAction<any>>;
};

export const ViewBookingContext = React.createContext<ViewBookingContextValue>({
  newPlan: null,
  setNewPlan: () => {},
  activity: null,
  setActivity: () => {},
  seatOption: null,
  setSeatOption: () => {},
  priceTypes: null,
  setPriceTypes: () => {},
  bookingPrice: null,
  setBookingPrice: () => {},
  clearData: () => {},
  productPrice: 0,
  newVenue: null,
  setNewVenue: () => {},
});

export function useViewBookingContext() {
  return React.useContext(ViewBookingContext);
}

export function changeQuantityItem(
  newQuantity: number,
  setBookingPrice: Dispatch<SetStateAction<any>>,
  priceType: any
) {
  if (priceType.quantity && newQuantity < priceType.quantity) {
    setBookingPrice((prev: number | null) => {
      if (prev) return prev - (priceType.amount ?? priceType.priceType.amount);
      return prev;
    });
  } else {
    setBookingPrice((prev: number | null) => {
      if (prev) return prev + (priceType.amount ?? priceType.priceType.amount);
      return prev;
    });
  }
}

export default function ViewBooking() {
  const { id: reservationId } = useParams<{ id: string }>();
  const [newPlan, setNewPlan] = useState<Plan | null>(null);
  const [newVenue, setNewVenue] = useState<Venue | null>(null);
  const [newActivity, setActivity] = useState<Activity | null>(null);
  const [newSeatOption, setSeatOption] = useState<SeatOption | null>(null);
  const [priceTypes, setPriceTypes] = useState<PriceType[] | null>(null);
  const [bookingPrice, setBookingPrice] = useState<number | null>(null);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);

  function clearData() {
    setActivity(null);
    setNewPlan(null);
    setSeatOption(null);
    setPriceTypes(null);
    setBookingPrice(null);
  }

  const { loading, error, data } = useQuery(GET_BOOKING, {
    variables: { id: reservationId },
  });

  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useQuery(GET_PRODUCT_ORDERS, {
    skip:
      !data ||
      !data.reservation ||
      !data.reservation.booking ||
      !data.reservation.booking.id,
    variables: { id: data?.reservation.booking.id },
  });

  const {
    loading: paidItemLoading,
    error: paidItemError,
    data: paidItemData,
  } = useQuery(GET_BOOKING_PAID_ITEMS, {
    skip:
      !data ||
      !data.reservation ||
      !data.reservation.booking ||
      !data.reservation.booking.id,
    variables: { id: data?.reservation.booking.id },
  });

  const [sending, setSending] = useState<boolean>(false);
  const [disableBtnSendMail, setDisableBtnSendMail] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const { showNotification } = useNotifications();
  const [editBooking] = useMutation(EDIT_BOOKING_MUTATION);
  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION);
  const [reservationItems, setReservationItems] = useState<ReservationItem[]>(
    []
  );
  const [saveBookingEdit, { loading: savingBookingEdit }] = useMutation(
    SAVE_BOOKING_EDIT_MUTATION
  );

  useEffect(() => {
    setEmail(data?.reservation.booking.email);
    setReservationItems(data?.reservation.items ?? []);
    setPhoneNumber(data?.reservation.booking.phoneNumber);
    setBookingPrice(data?.reservation.booking.paymentAmount);
    if (data?.reservation.booking.status == "CONFIRMED") {
      setDisableBtnSendMail(false);
    } else {
      setDisableBtnSendMail(true);
    }
  }, [data]);

  if (loading || productLoading || paidItemLoading) return <>loading...</>;
  if (error || productError || paidItemError)
    return (
      <>
        Error!{" "}
        {error?.message || productError?.message || paidItemError?.message}
      </>
    );

  const reservation: Reservation = data.reservation;
  const products: Products[] = productData.productOrders;
  const bookingPaidItems: BookingPaidItem[] = paidItemData.bookingPaidItems;
  const activity = getActivity(
    reservation.activityId,
    reservation.booking?.planSnapshot?.activities
  );
  const productsPriceAmount = products.reduce((total, product) => {
    return (
      total +
      (product.price ?? product.product?.sellingPrice ?? 0) * product.quantity
    );
  }, 0);

  const paperBagPriceAmount = products.reduce((total, product) => {
    if (product.product.template.withPaperbag) {
      return total + ITEM_BAG_FEE_JPY;
    }
    return total;
  }, 0);

  function handleEditBooking() {
    setLoadingSave(true);
    const editData: DataEditItem[] = [];
    if (data?.reservation.booking.email != email) {
      editData.push({
        fieldName: "Customer email",
        newValue: JSON.stringify({ text: email }),
        oldValue: JSON.stringify({ text: data?.reservation.booking.email }),
        editStatus: EditStatus.TOUCHED,
      });
    }

    if (data?.reservation.booking.phoneNumber != phoneNumber) {
      editData.push({
        fieldName: "Customer Phone Number",
        newValue: JSON.stringify({ text: phoneNumber }),
        oldValue: JSON.stringify({
          text: data?.reservation.booking.phoneNumber,
        }),
        editStatus: EditStatus.TOUCHED,
      });
    }

    if (newPlan) {
      if (
        reservation?.activity?.venue &&
        newVenue?.id !== reservation?.activity?.venue?.id
      ) {
        editData.push({
          fieldName: "Venue",
          newValue: JSON.stringify({
            id: newVenue?.id,
            name: newVenue?.name,
          }),
          oldValue: JSON.stringify({
            id: reservation?.activity?.venue?.id,
            name: reservation?.activity?.venue?.name,
          }),
          editStatus: EditStatus.TOUCHED,
        });
      }

      editData.push({
        fieldName: "Plan name",
        newValue: JSON.stringify({ text: newPlan?.name }),
        oldValue: JSON.stringify({ text: reservation?.activity?.plan?.name }),
        editStatus: EditStatus.TOUCHED,
      });
      editData.push({
        fieldName: "Plan id",
        newValue: JSON.stringify({ text: newPlan?.id }),
        oldValue: JSON.stringify({ text: reservation?.activity?.plan?.id }),
        editStatus: EditStatus.TOUCHED,
      });
      editData.push({
        fieldName: "Seat option",
        newValue: JSON.stringify({
          id: newSeatOption?.id,
          title: newSeatOption?.title,
          price: newSeatOption?.price,
        }),
        oldValue: JSON.stringify({
          id: reservation.seatOption.id,
          title: reservation.seatOption.title,
          price: reservation.seatOption.price,
        }),
        editStatus: EditStatus.TOUCHED,
      });
      reservation.items.forEach((item: ReservationItem) => {
        editData.push({
          fieldName: "Price type",
          newValue: JSON.stringify({
            id: item.id,
            priceType: item.priceType.id,
            quantity: item.quantity,
            unitType: item.priceType.unitType,
          }),
          oldValue: JSON.stringify({
            id: item.id,
            priceType: item.priceType.id,
            quantity: item.quantity,
            unitType: item.priceType.unitType,
          }),
          editStatus: EditStatus.REMOVED,
        });
      });
      priceTypes?.forEach((item: PriceType) => {
        editData.push({
          fieldName: "Price type",
          newValue: JSON.stringify({
            priceType: item.priceTypeId,
            quantity: item.quantity,
            unitType: item.unitType,
          }),
          oldValue: JSON.stringify({
            priceType: item.priceTypeId,
            quantity: item.quantity,
            unitType: item.unitType,
          }),
          editStatus: EditStatus.ADDED,
        });
      });
    }

    if (bookingPrice !== reservation.booking.paymentAmount) {
      editData.push({
        fieldName: "Payment amount",
        newValue: JSON.stringify({ text: bookingPrice }),
        oldValue: JSON.stringify({ text: reservation.booking.paymentAmount }),
        editStatus: EditStatus.TOUCHED,
      });
    }

    if (bookingPrice != reservation.booking.paymentAmount && !newPlan) {
      reservationItems?.forEach((item: ReservationItem, index: number) => {
        if (item.quantity !== reservation.items[index].quantity) {
          editData.push({
            fieldName: "Item " + item.priceType.name + "quantity",
            newValue: JSON.stringify({
              text: item.quantity,
            }),
            oldValue: JSON.stringify({
              text: reservation.items[index].quantity,
            }),
            editStatus: EditStatus.TOUCHED,
          });
        }
      });
    }

    editBooking({
      variables: {
        bookingId: reservation.booking.id,
        email,
        phoneNumber,
        newPaymentAmount: bookingPrice ?? null,
        reservationItems:
          bookingPrice != reservation.booking.paymentAmount && !newPlan
            ? reservationItems.map((i) => ({ id: i.id, quantity: i.quantity }))
            : null,
        newPlanId: newPlan?.id ?? null,
        newActivityId: newActivity?.id ?? null,
        newSeatOptionId: newSeatOption?.id ?? null,
        newPriceTypes: priceTypes
          ? priceTypes?.map((p) => ({
              id: p.id,
              priceTypeId: p.priceTypeId,
              quantity: p.quantity,
            }))
          : null,
      },
      refetchQueries: ["GetBookingQuery"],
    })
      .then(async (data: any) => {
        console.log(data);
        await saveBookingEdit({
          variables: {
            input: { editData: editData, bookingId: reservation.booking.id },
          },
        }).catch((error: any) => {
          console.log(error);
        });
        showNotification({
          message: `予約の編集が完了しました`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        showNotification({
          message: `予約の編集に失敗しました! ${err}`,
          severity: "error",
        });
      })
      .finally(() => {
        setLoadingSave(false);
      });
  }

  async function handleSendEmail() {
    setSending(true);
    sendEmail({
      variables: { bookingId: reservation.booking.id },
    })
      .then(() => {
        setSending(false);
        showNotification({
          message: `予約確定メール再度送信されました。`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        setSending(false);
        showNotification({
          message: `予約確定メール送信に失敗しました! ${err}`,
          severity: "error",
        });
      });
  }

  return (
    <ViewBookingContext.Provider
      value={{
        newPlan,
        setNewPlan,
        activity: newActivity,
        setActivity,
        seatOption: data?.booking?.seatOption ?? null,
        setSeatOption,
        priceTypes,
        setPriceTypes,
        bookingPrice,
        setBookingPrice,
        clearData,
        productPrice: getpaidItemPrice(bookingPaidItems),
        newVenue,
        setNewVenue,
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Button
          sx={{
            backgroundColor: "white",
            color: "#c8a063",
            borderColor: "#c8a063",
            "&:hover": {
              borderColor: "#c8a063",
            },
          }}
          variant="outlined"
          href="/bookings"
        >
          予約管理に戻る
        </Button>
        <LoadingButton
          loading={loadingSave}
          sx={{
            color: "#ffffff",
            backgroundColor: "#c8a063",
            margin: "0.5em",
            "&:hover": {
              backgroundColor: "#a37939",
            },
          }}
          variant="contained"
          onClick={handleEditBooking}
        >
          保存
        </LoadingButton>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <Stack spacing={2}>
              <TextField
                textcolor={
                  bookingStatusColors[
                    reservation.booking.status as Exclude<
                      Booking["status"],
                      "CREATED"
                    >
                  ]
                }
                readOnly={true}
                label="予約ステータス"
                value={
                  bookingStatusTranslations[
                    reservation.booking.status as Exclude<
                      Booking["status"],
                      "CREATED"
                    >
                  ]
                }
              />
              {reservation.booking?.status === "CANCELLED" && (
                <>
                  <TextField
                    readOnly
                    label="キャンセル料"
                    value={
                      reservation.booking.cancellationFee
                        ? reservation.booking.cancellationFee + " JPY"
                        : "0 JPY"
                    }
                  />
                  <TextField
                    readOnly
                    label="キャンセル理由欄"
                    value={reservation.booking.cancellationReason}
                  />
                </>
              )}
              <TextField
                readOnly={true}
                label="予約ID"
                value={reservation.booking.id}
              />
              <TextField
                readOnly={true}
                label="店舗名"
                value={newVenue?.name ?? reservation.activity.venue.name}
              />
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={9.4}>
                  <TextField
                    readOnly={true}
                    multiline={true}
                    label="プラン名"
                    value={
                      newPlan?.name ??
                      reservation?.booking?.planSnapshot?.name ??
                      reservation.activity.plan.name
                    }
                  />
                </Grid>
                <ChangePlanButton
                  items={reservationItems}
                  oldPlan={reservation.activity.plan.id}
                />
              </Grid>
              <TextField
                readOnly={true}
                multiline={true}
                label="コース名"
                value={
                  newActivity
                    ? newActivity.name
                    : reservation.booking?.planSnapshot?.activities
                    ? activity?.name
                    : reservation.activity?.json?.json?.name
                }
              />
              <TextField
                readOnly={true}
                multiline={true}
                label="お席タイプ/お席詳細"
                value={`${
                  newSeatOption?.title ?? reservation.seatOption.title
                }\n${
                  newSeatOption?.description ??
                  reservation.seatOption.description
                }`}
              />
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={4}>
                  <TextField
                    readOnly={true}
                    label="予約日時"
                    value={reservation.datetime.substr(0, 16)}
                  />
                </Grid>
                <Grid item xs={7.7}>
                  {(reservation.status === "NOT_YET_RESERVED" ||
                    reservation.status === "RESERVED") && (
                    <ChangeDateTimeButton
                      bookingId={reservation.booking.id}
                      reservationId={reservationId ?? ""}
                      currentReservationDateTime={reservation.datetime}
                    />
                  )}
                </Grid>
              </Grid>
              {priceTypes
                ? priceTypes.map((p, index) => (
                    <Stack key={index} direction="row" spacing={2}>
                      <TextField
                        readOnly={true}
                        label="ITEM_NAME"
                        value={p.priceType}
                      />
                      <TextField
                        type="number"
                        label="ITEM_QUANTITY"
                        value={p.quantity}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const newQuantity = Number(e.target.value);
                          if (newQuantity < 1) return;
                          changeQuantityItem(newQuantity, setBookingPrice, p);
                          setPriceTypes((items: PriceType[] | null) => {
                            if (!items) return items;
                            return items!.map((item: PriceType) => {
                              if (p.id === item.id) {
                                return {
                                  ...item,
                                  quantity: newQuantity,
                                };
                              }
                              return item;
                            });
                          });
                        }}
                      />
                    </Stack>
                  ))
                : reservationItems.map((i, index) => (
                    <Stack key={index} direction="row" spacing={2}>
                      <TextField
                        readOnly={true}
                        label="予約タイプ"
                        value={
                          getPriceType(i.priceType.id, activity?.priceTypes)
                            ? getPriceType(i.priceType.id, activity?.priceTypes)
                                ?.name
                            : i.priceType.name
                        }
                      />
                      <TextField
                        readOnly={true}
                        label="単価"
                        value={
                          getPriceType(i.priceType.id, activity?.priceTypes)
                            ? getPriceType(i.priceType.id, activity?.priceTypes)
                                ?.amountInMinorUnits
                            : i.priceType.amount
                        }
                      />
                      <TextField
                        type="number"
                        label="予約人数"
                        value={i.quantity}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const newQuantity = Number(e.target.value);
                          if (newQuantity < 1) return;
                          changeQuantityItem(newQuantity, setBookingPrice, i);
                          setReservationItems((items: ReservationItem[]) => {
                            return items?.map((item: ReservationItem) => {
                              if (i.id === item.id) {
                                return {
                                  ...item,
                                  quantity: newQuantity,
                                };
                              }
                              return item;
                            });
                          });
                        }}
                      />
                    </Stack>
                  ))}
              <TextField
                readOnly={true}
                label="プラン金額合計"
                value={`${
                  (bookingPrice
                    ? bookingPrice
                    : reservation.booking.paymentAmount) -
                  (productsPriceAmount + paperBagPriceAmount)
                } ${reservation.booking.paymentCurrency}`}
              />
              {productsPriceAmount > 0 && (
                <TextField
                  readOnly={true}
                  label="お祝いアイテム"
                  value={`${productsPriceAmount + paperBagPriceAmount} ${
                    reservation.booking.paymentCurrency
                  }`}
                />
              )}
              <TextField
                readOnly={true}
                label="お支払い方法"
                value={
                  paymentMethodTranslation[reservation.booking.payment.method]
                }
              />
              <TextField
                readOnly={true}
                label="ご利用目的"
                value={`${
                  reservation?.booking?.scene == "1"
                    ? "その他"
                    : reservation?.booking?.scene == "2"
                    ? "回答しない"
                    : reservation?.booking?.tag?.name ?? ""
                }`}
              />
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Card>
              <Stack spacing={2}>
                <TextField
                  textcolor={"red"}
                  readOnly={true}
                  multiline={true}
                  label="お客様からのコメント"
                  value={reservation.specialRequests}
                />
                {reservation.answers &&
                  reservation.answers.map((a, index) => (
                    <Stack key={index} spacing={2}>
                      <TextField
                        readOnly={true}
                        multiline={true}
                        label="質問"
                        value={a.question}
                      />
                      <TextField
                        textcolor={"red"}
                        readOnly={true}
                        label="回答"
                        value={a.answer}
                      />
                    </Stack>
                  ))}
              </Stack>
            </Card>
            <Card>
              <Stack spacing={2}>
                <TextField
                  readOnly={true}
                  label="ご予約名"
                  value={`${reservation.booking.familyName} ${reservation.booking.givenName} 様 (${reservation.booking.familyNameFurigana} ${reservation.booking.givenNameFurigana})`}
                />
                <TextField
                  label="お客様電話番号"
                  value={phoneNumber}
                  onChange={(e: any) => {
                    setPhoneNumber(e.target.value);
                  }}
                />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={8}>
                    <TextField
                      label="お客様メールアドレス"
                      value={email}
                      onChange={(e: any) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Button
                    sx={{
                      color: "#ffffff",
                      backgroundColor: "#c8a063",
                      margin: "0.5em",
                      "&:hover": {
                        backgroundColor: "#a37939",
                      },
                    }}
                    disabled={
                      data?.reservation.booking.email !== email ||
                      loading ||
                      sending ||
                      disableBtnSendMail
                      // data?.reservation.booking.status !== 'CONFIRMED'
                    }
                    variant="contained"
                    onClick={handleSendEmail}
                  >
                    {sending === false ? "予約確定メール再送" : "送信中"}
                  </Button>
                </Grid>
              </Stack>
            </Card>
            {products &&
              products.map((product, index: number) => {
                return (
                  <Card key={product.id + index}>
                    <Stack spacing={2}>
                      <TextField
                        readOnly={true}
                        label="お祝いアイテム名"
                        value={
                          product?.product?.template?.overrideDisplayName ??
                          product?.product?.template?.displayName
                        }
                      />
                      <TextField
                        readOnly={true}
                        label="種類"
                        value={
                          product?.product?.overrideName ??
                          product?.product?.name
                        }
                      />
                      <TextField
                        readOnly={true}
                        label="お祝いアイテム注文ステータス"
                        value={itemOrderStatusTranslations[product.status]}
                      />
                      <TextField
                        readOnly={true}
                        label="お祝いアイテムを渡すタイミング"
                        value={
                          product?.product?.template?.planTemplates?.[0]
                            ?.whenToGive || ""
                        }
                      />
                      <TextField
                        readOnly={true}
                        label="お祝いアイテムの渡し方"
                        value={
                          product?.product?.template?.planTemplates?.[0]
                            ?.howToGive || ""
                        }
                      />
                      <Stack spacing={2} direction="row">
                        <TextField
                          readOnly={true}
                          label="金額"
                          value={
                            (product.price ?? product?.product?.sellingPrice) +
                            " JPY"
                          }
                        />
                        <TextField
                          readOnly={true}
                          label="数量"
                          value={product.quantity}
                        />
                      </Stack>
                      {product?.product?.template?.templateImages?.map(
                        (image, idx: number) => {
                          return (
                            <Box
                              key={"ItemImage_" + index + "_" + idx}
                              component="img"
                              sx={{
                                height: 200,
                                width: 350,
                                maxHeight: { xs: 200, md: 150 },
                                maxWidth: { xs: 350, md: 250 },
                              }}
                              alt="お祝いアイテムのイメージ"
                              src={image.imageUrl}
                            />
                          );
                        }
                      )}
                    </Stack>
                  </Card>
                );
              })}
            {bookingPaidItems && bookingPaidItems.length > 0 && (
              <Card>
                <Stack spacing={2}>
                  {bookingPaidItems.map((paidItem, index: number) => {
                    return (
                      <div style={{ display: "flex", gap: "16px" }} key={index}>
                        <TextField
                          readOnly={true}
                          label="有料オプション名"
                          value={paidItem.planPaidItem.name}
                        />
                        <TextField
                          readOnly={true}
                          label="単価"
                          value={paidItem.planPaidItem.price}
                        />
                        <TextField
                          readOnly={true}
                          label="個数"
                          value={paidItem.quantity}
                        />
                      </div>
                    );
                  })}
                </Stack>
              </Card>
            )}
          </Stack>
        </Grid>
      </Grid>
    </ViewBookingContext.Provider>
  );
}

function getpaidItemPrice(
  paidItems: { planPaidItem: { price: number }; quantity: number }[]
): number {
  return paidItems
    .filter((i) => i.quantity > 0)
    .reduce((init, item) => init + item.planPaidItem.price * item.quantity, 0);
}

export function getActivity(
  id: string | undefined,
  activities: ActivityJson[] | undefined
) {
  const activity = activities?.find((a: ActivityJson) => a.id === id) ?? null;
  return activity;
}

export function getPriceType(
  id: string | undefined,
  priceTypes: PriceTypeJson[] | undefined
): PriceTypeJson | null {
  if (!id || !priceTypes) return null;
  const priceType = priceTypes.find((p) => p.id === id) ?? null;
  return priceType;
}

export function getUnitType(type: string) {
  return type === "PERSON" ? "名" : "セット";
}

export const SAVE_BOOKING_EDIT_MUTATION = gql`
  mutation SaveBookingEditMutation($input: SaveBookingEditInput!) {
    saveBookingEdit(input: $input) {
      success
      message
    }
  }
`;

const GET_BOOKING = gql`
  query GetBookingQuery($id: ID!) {
    reservation(id: $id) {
      status
      datetime
      specialRequests
      activityId
      activity {
        name
        venue {
          id
          name
        }
        plan {
          id
          name
        }
        json {
          json {
            name
          }
        }
      }
      seatOption {
        title
        description
        price
      }
      booking {
        id
        status
        familyName
        givenName
        familyNameFurigana
        givenNameFurigana
        email
        phoneNumber
        cancellationReason
        cancellationFee
        paymentAmount
        paymentCurrency
        scene
        payment {
          method
        }
        tag {
          id
          name
        }
        planSnapshot {
          name
          activities {
            id
            name
            priceTypes {
              id
              name
              unitType
              amountInMinorUnits
              amount
            }
          }
        }
      }
      items {
        id
        quantity
        priceType {
          id
          name
          unitType
          amount
        }
      }
      answers {
        question
        answer
      }
    }
  }
`;

const GET_PRODUCT_ORDERS = gql`
  query GetProductOrderByBooking($id: ID!) {
    productOrders(id: $id) {
      id
      quantity
      productId
      status
      price
      product {
        name
        overrideName
        templateId
        sellingPrice
        template {
          displayName
          overrideDisplayName
          withPaperbag
          templateImages {
            imageUrl
          }
          planTemplates {
            id
            whenToGive
            howToGive
          }
        }
      }
    }
  }
`;

const GET_BOOKING_PAID_ITEMS = gql`
  query GetBookingPaidItems($id: ID!) {
    bookingPaidItems(id: $id) {
      quantity
      planPaidItem {
        name
        price
      }
    }
  }
`;

const EDIT_BOOKING_MUTATION = gql`
  mutation EditBookingMutation(
    $bookingId: ID!
    $email: String!
    $phoneNumber: String!
    $newPaymentAmount: Int
    $reservationItems: [ReservationItemInput]
    $newPlanId: String
    $newActivityId: String
    $newPriceTypes: [NewPriceType]
    $newSeatOptionId: String
  ) {
    editBooking(
      input: {
        bookingId: $bookingId
        email: $email
        phoneNumber: $phoneNumber
        newPaymentAmount: $newPaymentAmount
        reservationItems: $reservationItems
        newPlanId: $newPlanId
        newActivityId: $newActivityId
        newPriceTypes: $newPriceTypes
        newSeatOptionId: $newSeatOptionId
      }
    ) {
      success
      link
    }
  }
`;

const SEND_EMAIL_MUTATION = gql`
  mutation SendEmailMutation($bookingId: ID!) {
    sendEmail(input: { bookingId: $bookingId }) {
      success
    }
  }
`;

interface Reservation {
  status: "NOT_YET_RESERVED" | "RESERVED" | "REJECTED" | "CANCELLED";
  datetime: string;
  specialRequests: string;
  activityId?: string;
  activity: Activity;
  seatOption: SeatOption;
  booking: Booking;
  items: ReservationItem[];
  answers?: ReservationAnswer[];
}

export interface Activity {
  id?: string;
  name: string;
  venue: Venue;
  plan: Plan;
  json: {
    json: {
      name: string;
    };
  };
}

interface Venue {
  id: string;
  name: string;
}

interface Plan {
  id: string;
  name: string;
}

interface SeatOption {
  id: string;
  title: string;
  description: string;
  price: number;
}

export interface ReservationItem {
  id: string;
  quantity: number;
  priceType: PriceType;
  unitType: string;
}

interface PriceType {
  id?: string;
  name: string;
  unitType?: string;
  quantity?: number;
  amount?: number;
  priceType?: string;
  priceTypeId?: string;
}

interface ReservationAnswer {
  question: string;
  answer: string;
}

interface BookingPaidItem {
  quantity: number;
  planPaidItem: {
    name: string;
    price: number;
  };
}

interface Products {
  id: string;
  productId: string;
  quantity: number;
  status: ProductOrderStatus;
  price?: number;
  product: {
    name: string;
    overrideName: string;
    templateId: string;
    sellingPrice: number;
    template: {
      withPaperbag?: boolean;
      displayName: string;
      overrideDisplayName: string;
      templateImages: {
        imageUrl: string;
      }[];
      planTemplates: {
        id: string;
        howToGive: string;
        whenToGive: string;
      }[];
    };
  };
}

export enum ProductOrderStatus {
  CREATED = "CREATED",
  REQUESTED = "REQUESTED",
  CONFIRMED = "CONFIRMED",
  SHIPPED = "SHIPPED",
  FAILED = "FAILED",
}
