import { useState } from "react";
import TableRoom from "./TableRoom";
import Toolbar from "./Toolbar";
import { CircularProgress, Typography } from "@mui/material";
import useTableRooms from "./useTableRooms";
import { format } from "date-fns";

export default function VenueRoom() {
  const [month, setMonth] = useState(new Date());
  const [room, setRoom] = useState<string | null>(null);

  const { loading, loadError, data: tables } = useTableRooms({
    month,
    roomId: room == "all" ? null : room,
  });

  if (loadError) return <>Error</>;

  return (
    <>
      <Typography variant="h5">
        在庫カレンダー　{format(month, "yyyy")}年 {format(month, "M")}月
      </Typography>
      <Toolbar
        month={month}
        setMonth={setMonth}
        room={room}
        setRoom={setRoom}
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <TableRoom
            tables={tables.tableRoomsAvailability}
            month={month}
            room={room}
            setRoom={setRoom}
          />
        </>
      )}
    </>
  );
}
