import { Box, Button, ButtonGroup, Stack } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { add, format, isBefore, set, sub } from "date-fns";
import styles from "../styles";
import { Dispatch, SetStateAction, useState } from "react";
import RoomSelector from "../RoomSelector";

type ToolbarProps = {
  month: Date;
  setMonth: Dispatch<SetStateAction<Date>>;
  room: string | null;
  setRoom: Dispatch<SetStateAction<string | null>>;
};

export default function Toolbar(props: ToolbarProps) {
  return (
    <Stack sx={styles.toolbar} direction="row">
      <MonthControls month={props.month} setMonth={props.setMonth} />
      <Box sx={styles.monthText}>
        {format(props.month, "yyyy")}年 {format(props.month, "M")}月
      </Box>
      <RoomSelector room={props.room} setRoom={props.setRoom} />
    </Stack>
  );

  function MonthControls(props: { month: Date; setMonth: (m: Date) => void }) {
    const currentMonth = set(new Date(), { date: 1, hours: 0, minutes: 0 });
    const previousMonth = sub(props.month, { months: 1 });
    const nextMonth = add(props.month, { months: 1 });
    const previousMonthIsPast = isBefore(previousMonth, currentMonth);

    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <ButtonGroup sx={styles.monthChangeButtons}>
          <Button
            sx={{ ...styles.monthChangeButton, borderRadius: "13px 0 0 13px" }}
            disabled={previousMonthIsPast}
            onClick={() => props.setMonth(previousMonth)}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: "16px" }} />
          </Button>
          <Button
            sx={{ ...styles.monthChangeButton, borderRadius: "0 13px 13px 0" }}
            onClick={() => props.setMonth(nextMonth)}
          >
            <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
          </Button>
        </ButtonGroup>
        <Button
          onClick={() => props.setMonth(currentMonth)}
          sx={{
            color: "#000",
            backgroundColor: "#fff",
            borderRadius: "7px",
            padding: "3px 15px",
            minWidth: "30px",
          }}
          variant="contained"
        >
          今月
        </Button>
      </Stack>
    );
  }
}
