import { useDrag, useDrop } from "react-dnd";
import React from "react";
import { PriceType } from "./ChangePlanButton";
import TextField from "../TextField";

const ItemType = "PRICE_TYPE";

interface PriceTypeItemProps {
  type: PriceType;
  index: number;
  movePriceType: (fromIndex: number, toIndex: number) => void;
}

export enum UnitType {
  PERSON = "名",
  SET = "セット",
}

const PriceTypeItem: React.FC<PriceTypeItemProps> = ({
  type,
  index,
  movePriceType,
}: PriceTypeItemProps) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: ItemType,
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        movePriceType(draggedItem.index, index);
        draggedItem.index = index; // Update the dragged item's index to avoid redundant hover calls.
      }
    },
  });

  return (
    <div
      ref={(node) => dragRef(dropRef(node))}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "grab",
        opacity: isDragging ? 0.3 : 1,
      }}
    >
      <TextField
        style={{ width: "100%", marginRight: 8, cursor: "grab" }}
        readOnly={true}
        value={type.name}
        label="Price type"
        sx={{
          "& .MuiInputBase-input": {
            cursor: "grab",
          },
        }}
      />
      <TextField
        style={{ width: "100%", marginRight: 8, cursor: "grab" }}
        readOnly={true}
        value={type.unitType === UnitType.SET ? "セット" : "名"}
        label="Unit type"
        sx={{
          "& .MuiInputBase-input": {
            cursor: "grab",
          },
        }}
      />
      <TextField
        style={{ width: "100%", cursor: "grab" }}
        readOnly={true}
        value={type.amount}
        label="Amount"
        sx={{
          "& .MuiInputBase-input": {
            cursor: "grab",
          },
        }}
      />
    </div>
  );
};

export default PriceTypeItem;
