import { Tooltip, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import "./EditorHeader.css";
import { useContext } from "react";
import { FormContext } from "../components/FormContext";

export default function EditorHeader({
  venueName,
  planName,
  handleSave,
  saveLoading,
}: EditorHeaderProps) {
  const { numUploading } = useContext(FormContext);
  return (
    <div className="EditorHeader">
      <div className="EditorHeaderSummary">
        <Typography align="center">{venueName}</Typography>
        <Tooltip title={planName}>
          <Typography align="center">{planName}</Typography>
        </Tooltip>
      </div>
      <div className="EditorHeaderCorner">
        <LoadingButton
          disabled={numUploading > 0}
          onClick={handleSave}
          loading={saveLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
        >
          <span>保存</span>
        </LoadingButton>
      </div>
    </div>
  );
}

interface EditorHeaderProps {
  venueName: string;
  planName: string;
  handleSave: () => void;
  saveLoading: boolean;
}
