import { useState } from "react";
import VenueRoom from "./VenueRoom";

export default function Calender() {
  const [hasUnsavedChange, setHasUnsavedChange] = useState(false);

  // This will show 'Leave site? Changes you made may not be saved.' confirmation window. according to browser language
  // Due to security reasons, the message text cannot be customized
  window.onbeforeunload = function (e: any) {
    e.preventDefault();
    if (hasUnsavedChange) {
      // only prevent first time user tries to leave with unsaved changes
      setHasUnsavedChange(false);
      return "";
    }
  };

  return <VenueRoom />;
}
