import {
  TableContainer,
  Table as MuiTable,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import styles from "../../styles";
import { DateTableCell } from "./Room/components/atoms/DateTableCell";
import { parseDate } from "../helpers";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import Modal from "./Modal";

type TableProps = {
  tables: {
    date: Date;
    times: {
      time: string;
      numBookings: number;
      numApprovals: number;
    }[];
  }[];
  month: Date;
  room: string | null;
  setRoom: Dispatch<SetStateAction<string | null>>;
};

export default function TableRoom({
  tables,
  month,
  room,
  setRoom,
}: TableProps) {
  const [modal, setModal] = useState(false);
  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const handleClick = (date: Date) => {
    setDateSelected(date);
    setModal(true);
  };

  return (
    <>
      <TableContainer sx={styles.tableContainer}>
        <MuiTable stickyHeader sx={styles.table}>
          <TableHead>
            <TableRow
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 15,
                background: "white",
              }}
            >
              {tables.map((table: any, index) => (
                <DateTableCell key={index} date={parseDate(table.date)} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={styles.planDivider}>
              {tables.map((table, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={styles.uneditableAvailabilityNotifications}
                  onClick={() => handleClick(table.date)}
                >
                  <Grid container>
                    {table.times.map((item, index) => {
                      const isDisabled =
                        item.numBookings === 0 && item.numApprovals === 0;
                      const stylesCell = isDisabled
                        ? styles.disabledCellItem
                        : styles.cellItem;
                      return (
                        <Grid key={index} style={stylesCell} item xs={6}>
                          <Box sx={{ padding: "10px 0" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "13px",
                              }}
                            >
                              {item.time}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: "17px",
                                color:
                                  item.numApprovals >= item.numBookings
                                    ? "#FF4A4A"
                                    : "",
                              }}
                            >
                              {item.numApprovals}/{item.numBookings}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </MuiTable>
      </TableContainer>
      {dateSelected && (
        <Modal
          value={modal}
          setValue={setModal}
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
          roomSelected={room}
        />
      )}
    </>
  );
}
