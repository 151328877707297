import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";

interface SelectProps {
  label: string;
  options: { value: string; name: string }[];
  value: string;
  onChange: (value: string) => void;
  size?: "small" | "medium";
  sx?: any;
}

export default function Select(props: SelectProps) {
  return (
    <FormControl>
      <InputLabel sx={props.sx ? { marginTop: "-5px", fontSize: "14px" } : {}}>
        {props.label}
      </InputLabel>
      <MuiSelect
        sx={props.sx ? props.sx : { backgroundColor: "#fff" }}
        size={props.size ? props.size : "medium"}
        label={props.label}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      >
        {props.options.map((o, index) => (
          <MenuItem key={index} value={o.value}>
            {o.name}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
