import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, { useState, useCallback, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "../TextField";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useNotifications } from "../Notification";
import { gql, useLazyQuery } from "@apollo/client";
import {
  Activity,
  ReservationItem,
  useViewBookingContext,
} from "../../pages/Bookings/View";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PriceTypeOldItem from "./PriceTypeOldItem";
import PriceTypeItem, { UnitType } from "./PriceTypeItem";

export type SeatOption = {
  id?: string;
  title?: string;
  price?: number;
};

export type PriceType = {
  id: string;
  name?: string;
  active?: boolean;
  unitType?: string;
  priceType?: string;
  amount?: number;
  quantity?: number;
};

function checkQuantityPriceTypes(
  priceTypes: Array<{ quantity: number }>
): boolean {
  let isCheck = false;
  priceTypes.forEach((p) => {
    if (p.quantity > 0) {
      isCheck = true;
    }
  });
  return isCheck;
}

export default function ChangePlanButton({
  oldPlan,
  items,
}: {
  oldPlan: string;
  items: ReservationItem[];
}) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        sx={{
          color: "#ffffff",
          backgroundColor: "#c8a063",
          margin: "0.3em",
          "&:hover": {
            backgroundColor: "#a37939",
          },
        }}
        variant="contained"
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        計画を変更
      </Button>
      <ChangePlanDialog
        oldPlan={oldPlan}
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        items={items}
      />
    </>
  );
}

function ChangePlanDialog(props: {
  items: ReservationItem[];
  oldPlan: string;
  open: boolean;
  closeDialog: () => void;
}) {
  const {
    setNewPlan,
    setNewVenue,
    setSeatOption,
    setActivity,
    setPriceTypes: setNewPriceTypes,
    setBookingPrice,
    productPrice,
  } = useViewBookingContext();
  const { showNotification } = useNotifications();
  const [getPlan, { data: plan }] = useLazyQuery(GET_PLAN);
  const [planId, setPlanId] = useState<string | null>(null);
  const [activityId, setActivityId] = useState<string | null>(null);
  const [seatOptionId, setseatOptionId] = useState<string | null>(null);

  const [seatOptions, setSeatOptions] = useState<SeatOption[]>([]);
  const [priceTypes, setPriceTypes] = useState([]);
  const [priceTypesOld, setPriceTypesOld] = useState<ReservationItem[]>(
    props.items ?? []
  );

  useEffect(() => {
    setPriceTypesOld(props.items ?? []);
  }, [props.items]);

  const handleChangeActivity = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const activityId = (event.target as HTMLInputElement).value;
    const activityItem = plan?.plan?.activities?.find(
      (a: Activity) => a.id === activityId
    );
    setActivityId(activityId ?? null);
    setseatOptionId(activityItem?.seatOptions?.[0].id as string);
    setSeatOptions(activityItem.seatOptions ?? []);
    setPriceTypes(activityItem?.priceTypes?.filter((a: any) => a.active) ?? []);
  };

  const hanldeGetPlan = async () => {
    if (props.oldPlan === planId) {
      return showNotification({
        message: "新しい計画は現在の計画と重複してはなりません。",
        severity: "error",
      });
    }
    await getPlan({ variables: { planId } }).then((data: any) => {
      if (!data?.data?.plan) {
        return showNotification({
          message: "プランを取得できませんでした! " + planId,
          severity: "error",
        });
      }
    });
  };

  const hanldeChangePlan = async () => {
    const newPriceTypes = priceTypes.map(
      (priceType: PriceType, index: number) => {
        if (priceTypesOld?.[index]) {
          return {
            id: priceTypesOld[index].id,
            priceType: priceType.name,
            priceTypeId: priceType.id,
            quantity: priceTypesOld[index].quantity,
            unitType: priceType.unitType === UnitType.SET ? "セット" : "名",
            amount: priceType.amount,
          };
        }
        return {
          priceType: priceType.name,
          priceTypeId: priceType.id,
          quantity: 0,
          unitType: priceType.unitType === UnitType.SET ? "セット" : "名",
          amount: priceType.amount,
        };
      }
    );

    if (!checkQuantityPriceTypes(newPriceTypes)) {
      return showNotification({
        message:
          "価格タイプ数量を確認してください。有効な数量の価格がありません。",
        severity: "error",
      });
    }

    const activity = plan?.plan?.activities?.find(
      (a: Activity) => a.id === activityId
    );

    const newSeatOption = seatOptions.find(
      (option: SeatOption) => option.id === seatOptionId
    );

    let newPrice = newPriceTypes.reduce((init, p) => {
      if (p.amount && p.quantity) {
        return init + p.amount * p.quantity;
      }
      return init;
    }, 0);

    if (newSeatOption?.price) {
      newPrice += newSeatOption.price;
    }

    if (plan?.plan?.basePrice) {
      newPrice += plan?.plan?.basePrice;
    }

    setNewPlan(plan?.plan);
    setSeatOption({
      id: newSeatOption?.id,
      title: newSeatOption?.title,
      price: newSeatOption?.price ?? 0,
    });
    setActivity(activity ?? null);
    setNewPriceTypes(newPriceTypes);
    setBookingPrice(newPrice + productPrice);
    setNewVenue(activity?.venue);
    props.closeDialog();
  };

  const movePriceType = useCallback(
    (fromIndex: number, toIndex: number) => {
      const updatedPriceTypes = [...priceTypes];
      const [movedItem] = updatedPriceTypes.splice(fromIndex, 1);
      updatedPriceTypes.splice(toIndex, 0, movedItem);
      setPriceTypes(updatedPriceTypes);
    },
    [priceTypes]
  );

  const movePriceTypeOld = useCallback(
    (fromIndex: number, toIndex: number) => {
      const updatedPriceTypes = [...priceTypesOld];
      const [movedItem] = updatedPriceTypes.splice(fromIndex, 1);
      updatedPriceTypes.splice(toIndex, 0, movedItem);
      setPriceTypesOld(updatedPriceTypes);
    },
    [priceTypesOld]
  );

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          width: { xl: "55vw", md: "85vw", xs: "100vw" },
        },
      }}
      maxWidth="xl"
      open={props.open}
      onClose={props.closeDialog}
    >
      <DialogTitle sx={{ paddingBottom: 0 }}>計画を変更する</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", paddingTop: 3 }}>
          <Grid spacing={0.2} container justifyContent="space-between">
            <Grid xs={10.6}>
              <TextField
                label="プランID"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPlanId(e.target.value)
                }
              />
            </Grid>
            <Grid xs={1.2}>
              <Button
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#c8a063",
                  width: "100%",
                  height: "100%",
                  "&:hover": {
                    backgroundColor: "#a37939",
                  },
                }}
                variant="contained"
                onClick={hanldeGetPlan}
              >
                検索計画
              </Button>
            </Grid>
          </Grid>
        </Box>
        {plan?.plan ? (
          <Stack sx={{ marginTop: 3 }}>
            <Box sx={{ marginBottom: 3 }}>
              <Typography sx={{ fontSize: 16, fontWeight: 500 }} variant="h3">
                プラン情報
              </Typography>
              <Stack sx={{ marginTop: 2 }} spacing={2}>
                <TextField label="プラン名" value={plan.plan.name} readOnly />
                <TextField
                  label="基本価格"
                  value={plan.plan.basePrice}
                  readOnly
                />
              </Stack>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
              <Typography sx={{ fontSize: 16, fontWeight: 500 }} variant="h3">
                活動
              </Typography>
              <Stack sx={{ marginTop: 0.5 }} spacing={2}>
                <FormControl style={{ width: "100%" }}>
                  <RadioGroup
                    value={activityId}
                    onChange={handleChangeActivity}
                  >
                    {plan.plan.activities?.map((a: Activity) => {
                      return (
                        <FormControlLabel
                          label={a?.name ?? ""}
                          control={<Radio />}
                          value={a?.id as string}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Box>
            {activityId && seatOptions ? (
              <Box sx={{ marginBottom: 2 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 500 }} variant="h3">
                  座席オプション
                </Typography>
                <Stack sx={{ marginTop: 0.5 }} spacing={2}>
                  <FormControl style={{ width: "100%" }}>
                    <RadioGroup
                      value={seatOptionId}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setseatOptionId(e.target.value)
                      }
                    >
                      {seatOptions?.map((seatOption: SeatOption) => {
                        return (
                          <FormControlLabel
                            label={seatOption?.title ?? ""}
                            control={<Radio />}
                            value={seatOption?.id as string}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>
            ) : null}
            {priceTypes && priceTypes.length > 0 && (
              <DndProvider backend={HTML5Backend}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                  }}
                >
                  <Box style={{ width: "50%" }}>
                    <Typography
                      sx={{ fontSize: 16, fontWeight: 500, marginBottom: 2 }}
                      variant="h3"
                    >
                      新しい価格タイプ
                      <span style={{ fontSize: "12px", fontWeight: 400 }}>
                        (価格を希望の値までドラッグアンドドロップできます)
                      </span>
                    </Typography>
                    <Stack spacing={1}>
                      {priceTypes.map((type: PriceType, index: number) => {
                        return (
                          <PriceTypeItem
                            type={type}
                            index={index}
                            movePriceType={movePriceType}
                          />
                        );
                      })}
                    </Stack>
                  </Box>
                  <Box style={{ width: "50%" }}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        marginBottom: 0.4,
                        paddingLeft: 3,
                      }}
                      variant="h3"
                    >
                      旧価格タイプ
                      <span style={{ fontSize: "12px", fontWeight: 400 }}>
                        (価格を希望の値までドラッグアンドドロップできます)
                      </span>
                    </Typography>
                    <Stack spacing={1}>
                      {priceTypesOld?.map(
                        (type: ReservationItem, index: number) => {
                          return (
                            <PriceTypeOldItem
                              index={index}
                              type={type}
                              movePriceType={movePriceTypeOld}
                              priceTypes={priceTypes}
                              setPriceTypesOld={setPriceTypesOld}
                            />
                          );
                        }
                      )}
                    </Stack>
                  </Box>
                </Box>
              </DndProvider>
            )}
          </Stack>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            props.closeDialog();
          }}
        >
          キャンセル
        </Button>
        <Button color="warning" onClick={hanldeChangePlan}>
          変更
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const GET_PLAN = gql`
  query GetPlanQuery($planId: ID!) {
    plan(id: $planId) {
      id
      name
      basePrice
      activities {
        id
        name
        venue {
          id
          name
        }
        priceTypes {
          id
          name
          amount
          active
          unitType
        }
        seatOptions {
          id
          title
          price
          description
        }
      }
    }
  }
`;
