import { TableCell, TableCellProps, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { format, getDay } from "date-fns";
import { ja } from "date-fns/locale";
import s from "../../../../../styles";
import { SystemCssProperties } from "@mui/system";

export function DateTableCell({ date }: DateTableCellProps) {
  const day = useMemo(() => getDay(date), [date]);

  const classes: SystemCssProperties<{}>[] = [s.cell];

  return (
    <TableCell sx={classes}>
      {format(date, "d")}
      <br />
      {format(date, "eeee", { locale: ja })}
    </TableCell>
  );
}

type DateTableCellProps = {
  date: Date;
} & TableCellProps;
