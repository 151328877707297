import { Routes, Route, Navigate } from "react-router-dom";
import { useGlobalContext } from "./GlobalContext";

import * as pages from "./pages";

export default function WebappRoutes() {
  const publicRoutes = [
    { path: "/login", component: pages.Login },
    { path: "/forgot-password", component: pages.ForgotPassword },
    { path: "/reset-password/:resetCode", component: pages.ResetPassword },
  ];

  const protectedRoutes = [
    { path: "/", component: pages.HomePage },
    { path: "/bookings", component: pages.BookingsList },
    { path: "/bookings/:id", component: pages.ViewBooking },
    { path: "/plans/edit/:id", component: pages.PlanEditor },
    { path: "/plans/availability", component: pages.PlansAvailability },
    { path: "/plans/calender", component: pages.PlansCalender },
    { path: "/plans", component: pages.PlansList },
    { path: "/reports/costs", component: pages.CostsReport },
    {
      path: "/reports/dashboard/:id?",
      component: pages.Dashboard,
    },
    { path: "/sentry-error", component: pages.SentryError },
    { path: "/reviews", component: pages.ReviewsList },
  ];

  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<route.component />}
        />
      ))}
      {protectedRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <RequireAuth
              redirectTo={
                window.location.pathname === "/"
                  ? "/login"
                  : `/login?callbackUrl=${window.location.pathname}`
              }
            >
              <route.component />
            </RequireAuth>
          }
        />
      ))}
      <Route path="*" element={pages.PageNotFound()} />
    </Routes>
  );
}

function RequireAuth({ children, redirectTo }: any) {
  const { isLoggedIn } = useGlobalContext();

  return isLoggedIn ? children : <Navigate to={redirectTo} />;
}
