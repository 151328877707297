import { Reservation } from "..";
import ConfirmBooking from "./ConfirmReservation";
import RejectReservation from "./RejectReservation";
import CancelReservation from "./CancelReservation";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import ShowBooking from "./ShowBooking";
import Modal from "./Modal";

interface ActionButtonsProps {
  row: Reservation;
  refetch: () => void;
}

export default function ActionButtons(props: ActionButtonsProps) {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState<boolean>(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {loading && <CircularProgress size={16} />}
      <div style={{ display: loading ? "none" : "block" }}>
        {["REQUESTED", "ON_HOLD"].includes(props.row.booking.status) && (
          <>
            <ConfirmBooking reservation={props.row} setLoading={setLoading} />
            <RejectReservation
              reservation={props.row}
              setLoading={setLoading}
            />
            <Tooltip
              arrow
              title={
                <span
                  dangerouslySetInnerHTML={{
                    __html: (props.row.booking.onHoldReason ?? "保留").replace(
                      /\n/g,
                      "<br />"
                    ),
                  }}
                />
              }
            >
              <IconButton size="small" onClick={() => setModal(true)}>
                <ChangeHistoryIcon
                  style={{ color: "#f8f315" }}
                  fontSize="inherit"
                />
              </IconButton>
            </Tooltip>
            <Modal
              value={modal}
              bookingId={props.row.booking.id}
              reason={props.row.booking.onHoldReason}
              setValue={setModal}
              refetch={props.refetch}
            />
          </>
        )}
        {props.row.booking.status === "CONFIRMED" && (
          <>
            <CancelReservation
              reservation={props.row}
              setLoading={setLoading}
            />
            <ShowBooking reservation={props.row} setLoading={setLoading} />
          </>
        )}
      </div>
    </div>
  );
}
